import { Options, Vue } from "vue-class-component";
@Options({
  name: "SuccessPage",
  data() {
    return {
      time: 9,
    };
  },
  methods: {
    countDown: function () {
      if (this.time > 0) {
        setTimeout(() => {
          this.time--;
          this.countDown();
        }, 1000);
      } else {
        this.$router.push({
          path: "/account/login",
        });
      }
    },
  },
  mounted(): void {
    if (this.$route.params.status != "success") {
      this.$router.back();
    }
  },
})
export default class SuccessPage extends Vue {
  title = "<h3>Thank you!</h3>";
  message = "Please choose your next action below.";

  created() {
    if (this.$route.params.title) {
      this.title = this.$route.params.title.toString();
    }
    if (this.$route.params.message) {
      this.message = this.$route.params.message.toString();
    }
  }
}
